/*--------------------------------------------------------------
4. wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer,
.wpo-site-footer-s2 {
    background: #1E2E3E;
    position: relative;
    font-size: 15px;
    overflow: hidden;

    ul {
        list-style: none;
    }

    p {
        color: $white;
    }

    li {
        color: $white;
    }

    .container {
        position: relative;
    }

    .wpo-upper-footer {
        padding: 70px 0;
    }

    @media (max-width: 991px) {

        .wpo-upper-footer {
            padding: 50px 0 0;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer {
            padding: 60px 0 0;
        }
    }

    @media (max-width: 991px) {

        .wpo-upper-footer .col {
            min-height: 235px;
            margin-bottom: 70px;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer .col {
            min-height: auto;
            margin-bottom: 60px;
        }
    }

    .widget-title {
        margin-bottom: 30px;
    }

    @media (max-width: 767px) {

        .widget-title {
            margin-bottom: 20px;
        }
    }


    .widget-title {
        h3 {
            color: $white;
            margin: 0;
            position: relative;
            font-family: $heading-font;
            font-weight: 400;
            font-size: 34px;

            @media (max-width: 1399px) {
                font-size: 29px;
            }

            @media (max-width: 1199px) {
                font-size: 24px;
            }
        }
    }

    .about-widget {
        .logo {
            max-width: 180px;
        }

        .widget-title {
            margin-bottom: 0;
        }
    }


    .about-widget {
        p {
            margin-bottom: 0.8em;
            line-height: 1.9em;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul {
            overflow: hidden;
            padding-top: 10px;

            li {
                font-size: 22px;
                float: left;

                a {
                    color: #e7e7e7;
                    width: 36px;
                    height: 36px;
                    line-height: 40px;
                    background: rgba($color: $white, $alpha: 0.1);
                    display: block;
                    text-align: center;
                    @include rounded-border(50%);
                    font-size: 18px;

                    &:hover {
                        color: $white;
                        background: $theme-primary-color;
                    }

                    i {
                        &::before {
                            font-size: 14px;
                        }
                    }
                }
            }

            li+li {
                margin-left: 25px;

                @media(max-width:1200px) {
                    margin-left: 15px;
                }
            }
        }
    }

    .wpo-service-link-widget {
        padding-left: 70px;
    }

    .link-widget {
        overflow: hidden;


        @media screen and (min-width: 1200px) {
            padding-left: 75px;
        }

        @media (max-width: 1199px) {
            padding-left: 0;
        }



        @media (max-width: 767px) {
            max-width: 350px;
        }

        ul {
            li {
                position: relative;

                a {
                    color: $white;
                    font-size: 18px;
                    line-height: 35px;

                    &:hover {
                        color: $theme-primary-color;
                    }
                }
            }

            li+li {}
        }
    }

    .contact-ft {
        margin-top: 20px;

        ul {
            li {
                padding-bottom: 15px;
                position: relative;
                padding-left: 35px;
                color: #e5e3e3;
                font-size: 17px;

                i {
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                .fi:before {
                    font-size: 20px;
                    margin-right: 15px;
                }
            }
        }
    }

    .social-widget {
        ul {
            li {
                a {
                    display: block;
                    font-size: 16px;
                    font-weight: 600;
                    color: $white;
                    margin-bottom: 10px;

                    &:hover {
                        color: $theme-primary-color;
                    }

                    i {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    /*** newsletter-widget ***/
    .newsletter-widget {


        @media (max-width: 767px) {
            max-width: 350px;
        }

        p {
            margin-bottom: 0;
        }

        form {
            margin-top: 30px;
            position: relative;

            input {
                background-color: transparent;
                height: 55px;
                color: $white;
                padding: 6px 15px;
                border: 1px solid $white;
                box-shadow: none;
                border-radius: 0;
            }

            .submit {
                margin-top: 20px;

                button {
                    background: $theme-primary-color;
                    border: 1px solid transparent;
                    outline: 0;
                    color: $white;
                    width: 100%;
                    text-align: center;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 45px;
                }
            }
        }
    }

    .wpo-lower-footer {
        text-align: center;
        position: relative;

        .row {
            padding: 20px 0;
            position: relative;
            border-top: 1px dashed #30465C;
        }

        .copyright {
            display: inline-block;
            font-size: 15px;
            font-size: 0.9375rem;
            margin: 0;

            a {
                color: $white;
                text-decoration: none;
            }

            @media (max-width: 991px) {
                float: none;
                display: block;
            }
        }
    }

    .instagram {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0px -3px;

            li {
                -ms-flex: 0 0 33.33%;
                flex: 0 0 33.33%;
                max-width: 33.33%;
                margin-bottom: 5px;
                padding: 0px 3px;

                img {
                    width: 100%;
                }
            }
        }
    }
}

.wpo-site-footer-s2 {
    background: #0A1439;
    position: relative;
    z-index: 1;
    .shape-1 {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;

        svg {
            path {
                fill: #0B1742;
            }
        }
    }

    .shape-2 {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;

        svg {
            path {
                fill: #0B1742;
            }
        }
    }

    .widget.about-widget {
        text-align: center;

        @media (max-width:991px) {
            text-align: left;
        }
    }

    .widget-title {
        margin: 0 auto;
        margin-bottom: 20px;

        @media (max-width:991px) {
            margin: 0;
        }
    }

    .about-widget ul {
        text-align: center;
        display: flex;
        justify-content: center;

        @media (max-width:991px) {
            justify-content: flex-start;
            text-align: left;
        }
    }

    .link-widget {
        padding-left: 0;
    }

    .widget.link-widget.s2 {
        text-align: end;

        @media (max-width:991px) {
            text-align: left;
        }
    }
}