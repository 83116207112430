/*3.2 Arkitek-about-section */
.Arkitek-about-section,
.Arkitek-about-section-s3 {

    .about-left-item {
        margin-right: 40px;

        @media (max-width:1399px) {
            margin-right: 0;
        }

        @media (max-width:991px) {
            margin-bottom: 50px;
        }
    }

    .wpo-section-title {
        text-align: left;
        margin-bottom: 30px;

        @media (max-width:767px) {
            margin-bottom: 20px;
        }

        h2 {
            position: unset;
            margin-top: 0;
            line-height: 75px;
            margin-top: 10px;
            margin-bottom: 10px;

            @media (max-width:1399px) {
                font-size: 42px;
                line-height: 50px;
            }

            @media (max-width:767px) {
                font-size: 32px;
                line-height: 40px;
            }

            &::before {
                position: unset;
            }

            &::after {
                position: unset;
            }
        }

        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 35px;
            color: $text-light-color;
            margin-bottom: 0;

            @media (max-width:767px) {
                font-size: 16px;
                line-height: 30px;
            }
        }
    }

    .about-middle-item {
        @media (max-width:991px) {
            margin-bottom: 50px;
        }

        img {
            width: 100%;
        }
    }

    .about-right-item {
        text-align: left;
        margin-left: 49px;

        @media (max-width:1399px) {
            margin-left: 0;
        }

        p {
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 400;
            line-height: 37px;
            color: $text-light-color;

            @media (max-width:767px) {
                font-size: 16px;
                line-height: 30px;
            }

            span {
                font-family: $heading-font;
                font-size: 50px;
                font-weight: 400;
                color: $theme-primary-color-s2;

                @media (max-width:767px) {
                    font-size: 40px;
                }

            }
        }

        h2 {
            font-weight: 400;
            font-size: 30px;
            line-height: 50px;
            color: $heading-color;
            margin-top: 29px;
            margin-bottom: 0;

            @media (max-width:991px) {
                margin-top: 20px;
            }

            @media (max-width:767px) {
                line-height: 40px;
                margin-top: 15px;
            }
        }

        h3 {
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            color: $text-light-color;
        }

        .signeture-img {
            width: 200px;
            height: 80px;
            display: block;
            margin-top: 58px;

            @media (max-width:1399px) {
                margin-top: 30px;
            }

            @media (max-width:991px) {
                width: 150px;
                height: 60px;
            }

            @media (max-width:575px) {
                width: 120px;
                height: 50px;
            }
        }
    }

    &.s2 {
        padding-top: 75px;

        @media (max-width:991px) {
            padding-top: 90px;
        }

        @media (max-width:767px) {
            padding-top: 80px;
        }
    }
}

/*3.3 wpo-fun-fact-section */

.wpo-fun-fact-section,
.wpo-fun-fact-section-s2 {
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;

    .wpo-fun-fact-grids {
        border: 2px dashed $border-color;
        padding: 20px;
    }

    .wpo-fun-fact-grids .grid {
        width: 25%;
        float: left;
        border-top: 2px dashed $border-color;
        border-bottom: 2px dashed $border-color;
        border-left: 2px dashed $border-color;
        padding: 30px 0;
        padding-bottom: 19px;

        @include media-query(767px) {
            border: 2px dashed $border-color;
            border-bottom: 2px dashed transparent;
        }

        &:nth-child(1) {
            @include media-query(991px) {
                border-bottom: 2px dashed transparent;
            }
        }

        &:nth-child(4) {
            border-right: 2px dashed $border-color;

            @include media-query(767px) {
                border-bottom: 2px dashed $border-color;
            }
        }

        &:nth-child(2) {
            @include media-query(991px) {
                border-right: 2px dashed $border-color;
                border-bottom: 2px dashed transparent;
            }
        }

        @include media-query(991px) {
            width: 50%;
        }

        @include media-query(767px) {
            width: 100%;
            float: none;
        }
    }

    .grid {

        h3 {
            margin: 0 0 0.2em;
            font-family: $heading-font;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 50px;
            line-height: 60px;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: $dark-gray;

            @include media-query(767px) {
                line-height: 45px;
                font-size: 35px;
            }
        }
    }



    .grid h3+p {
        text-transform: uppercase;
        color: $dark-gray;
        font-family: $heading-font;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;

        @include media-query(767px) {
            font-size: 18px;
        }
    }

    &.s3 {
        padding-top: 80px;
    }

    .bg-shape {
        display: none;
    }
}

/*3.4 Arkitek-service-section */
.Arkitek-service-section,
.Arkitek-service-section-s2 {
    @media (max-width:767px) {
        padding-bottom: 50px;
    }

    .service-wrap {
        margin-top: 75px;
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width:767px) {
            margin-top: 35px;
        }

        .bg-image {
            position: absolute;
            left: 50%;
            top: 47%;
            transform: translate(-50%, -50%);
            width: 990px;
            height: 745px;
            z-index: -1;

            @media (max-width:1399px) {
                width: 755px;
                height: 100%;
            }

            @media (max-width:1199px) {
                width: 660px;
            }

            @media (max-width:991px) {
                width: 640px;
            }

            @media (max-width:767px) {
                display: none;
            }
        }

    }



    .service-item {
        border: 2px dashed #0000004d;
        display: flex;
        justify-content: space-between;
        padding: 40px 28px;
        padding-bottom: 20px;
        background: $white;
        max-width: 400px;
        margin-bottom: 30px;

        @media (max-width:1399px) {
            margin-right: 0;
            padding: 20px;
        }

        @media (max-width:1199px) {
            padding: 15px;
            margin-bottom: 30px;
        }

        @media (max-width:767px) {
            margin: 0 auto;
            margin-bottom: 30px;
        }




        .icon {
            margin-top: 10px;
            width: 80px;

            @media (max-width:767px) {
                margin-top: 0;
                width: 60px;
            }

            @media (max-width:575px) {
                width: 80px;
            }

            img {
                width: 100%;
            }
        }

        .text {
            padding-left: 30px;
            text-align: left;

            @media (max-width:1399px) {
                padding-left: 20px;
            }

            @media (max-width:1199px) {
                padding-left: 15px;
            }

            h2 {
                a {
                    font-family: $heading-font;
                    color: $heading-color;
                    font-weight: 400;
                    font-size: 30px;
                    display: inline-block;
                    transition: all .3s ease-in-out;

                    @media (max-width:1199px) {
                        font-size: 26px;
                    }

                    @media (max-width:575px) {
                        font-size: 20px;
                    }

                    &:hover {
                        color: $theme-primary-color;
                    }
                }
            }

            p {
                font-family: $base-font;
                font-weight: 400;
                font-size: 18px;
                color: $text-light-color;
                padding-top: 10px;

                @media (max-width:1399px) {
                    margin-bottom: 0;
                    padding-top: 0;
                }

                @media (max-width:1199px) {
                    font-size: 16px;
                }
            }
        }
    }

    .service-item-wrap {
        flex-basis: 50%;

        @media (max-width:991px) {
            flex-basis: 48%;
        }

        @media (max-width:767px) {
            flex-basis: 100%;
        }

        &:nth-child(2) {
            .service-item {
                margin-left: auto;
                flex: 1 1;

                @media (max-width:767px) {
                    margin: 0 auto;
                    margin-bottom: 30px;
                }

                .icon {
                    order: 1;

                    @media (max-width:767px) {
                        order: 0;
                    }
                }

                .text {
                    padding-right: 30px;
                    padding-left: 0;
                    text-align: right;

                    @media (max-width:1399px) {
                        padding-right: 20px;
                        padding-left: 0;
                    }

                    @media (max-width:1199px) {
                        padding-right: 15px;
                        padding-left: 0;
                    }

                    @media (max-width:767px) {
                        padding-right: 0;
                        padding-left: 15px;
                        text-align: left;
                    }
                }
            }
        }

        &:nth-child(4) {
            .service-item {
                margin-left: auto;
                flex: 1 1;

                .icon {
                    order: 1;

                    @media (max-width:767px) {
                        order: 0;
                    }
                }

                .text {
                    padding-right: 30px;
                    padding-left: 0;
                    text-align: right;

                    @media (max-width:1399px) {
                        padding-right: 20px;
                        padding-left: 0;
                    }

                    @media (max-width:1199px) {
                        padding-right: 15px;
                        padding-left: 0;
                    }

                    @media (max-width:767px) {
                        padding-right: 0;
                        padding-left: 15px;
                        text-align: left;
                    }
                }
            }
        }

        &:nth-child(6) {
            .service-item {
                margin-left: auto;
                flex: 1 1;

                .icon {
                    order: 1;

                    @media (max-width:767px) {
                        order: 0;
                    }
                }

                .text {
                    padding-right: 30px;
                    padding-left: 0;
                    text-align: right;

                    @media (max-width:1399px) {
                        padding-right: 20px;
                        padding-left: 0;
                    }

                    @media (max-width:1199px) {
                        padding-right: 15px;
                        padding-left: 0;
                    }

                    @media (max-width:767px) {
                        padding-right: 0;
                        padding-left: 15px;
                        text-align: left;
                    }
                }
            }
        }
    }

}

/* 3.5 Arkitek-project-section */
.Arkitek-project-section,
.Arkitek-project-section-s2 {
    .container-fluid {
        padding: 0;
    }

    .row {

        margin: 0 110px;

        @media (max-width:1760px) {
            margin: 0 50px;
        }

        @media (max-width:1499px) {
            margin: 0 20px;
        }

        @media (max-width:1199px) {
            margin: 0;
        }
    }


    .wpo-section-title {
        text-align: left;

        @media (max-width:991px) {
            text-align: center;
        }

        h2 {
            @media (max-width:1399px) {
                font-size: 48px;
                line-height: 70px;
            }

            @media (max-width:1199px) {
                font-size: 40px;
                line-height: 55px;
            }

            @media (max-width:767px) {
                font-size: 32px;
                line-height: 40px;
            }

            &::before {
                left: 0;
                transform: unset;
                width: 32%;

                @media (max-width:991px) {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            &::after {
                left: 0;
                transform: unset;
                width: 22%;

                @media (max-width:991px) {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    .project-btn {
        text-align: right;

        @media (max-width:991px) {
            text-align: center;
        }
    }



    .project-wrap {
        margin-top: 35px;

        @media (max-width:991px) {
            margin-top: 50px;
        }
    }


    .project-item {
        transition: all .3s;
        margin-top: 0px;

        .image {

            img {
                width: 100%;
            }
        }

        .text {
            margin-top: 30px;

            @media (max-width:1199px) {
                margin-top: 0;
            }

            @media (max-width:767px) {
                margin-top: 10px;
                text-align: center;
            }

            h2 {

                a {
                    font-family: $heading-font;
                    font-weight: 400;
                    font-size: 30px;
                    color: $heading-color;
                    display: inline-block;
                    padding-left: 80px;
                    transition: all .3s ease-in-out;
                    position: relative;

                    @media (max-width:1760px) {
                        padding-left: 70px;
                        font-size: 25px;
                    }

                    @media (max-width:1399px) {
                        padding-left: 50px;
                        font-size: 22px;
                    }

                    @media (max-width:1199px) {
                        padding-left: 30px;
                        font-size: 20px;
                    }

                    @media (max-width:991px) {
                        padding-left: 0;
                        font-size: 20px;
                    }

                    &:hover {
                        color: $theme-primary-color;
                    }

                    &::before {
                        position: absolute;
                        left: 10px;
                        top: 50%;
                        content: '';
                        height: 1px;
                        width: 50px;
                        background: $theme-primary-color-s2;

                        @media (max-width:1399px) {
                            width: 40px;
                            left: 5px;
                        }

                        @media (max-width:1199px) {
                            width: 20px;
                            left: 5px;
                        }

                        @media (max-width:991px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .project-slider {

        .owl-item.cloned {
            transition: all .3s;
        }

        .slick-slider {
            margin: 0 -10px;

            .slick-slide {
                padding: 0 10px;

                @media(max-width:767px) {
                    padding: 0;
                }
            }
        }

    }
}

/* 3.6 wpo-pricing-section */

.wpo-pricing-section,
.wpo-pricing-section-s2 {

    .wpo-section-title {

        h2 {
            @media (max-width:1199px) {
                font-size: 43px;
                line-height: 60px;
            }

            @media (max-width:767px) {
                font-size: 32px;
                line-height: 40px;
            }
        }
    }

    .wpo-pricing-wrap {
        margin-top: 35px;

        .wpo-pricing-item {
            border: 2px dashed #afbda852;
            background: transparent;

            @media(max-width:991px) {
                margin-bottom: 30px;
            }

            .wpo-pricing-top {
                padding: 30px;
                text-align: center;
                padding-top: 30px;
                padding-bottom: 0;

                .pricing-thumb {
                    display: inline-block;

                    span {
                        color: $dark-gray;
                        font-family: $heading-font;
                        font-weight: 400;
                        font-size: 35px;
                        line-height: 60px;
                    }
                }


                .wpo-pricing-text {
                    border-bottom: 1px solid #D9D9D9;

                    h2 {
                        strong {
                            font-size: 30px;
                            line-height: 50px;

                            @media (max-width:1199px) {
                                line-height: 37px;
                                font-size: 25px;
                            }
                        }

                        color: $theme-primary-color-s2;
                        font-size: 60px;
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        margin-bottom: 20px;
                        font-weight: 400;

                        @media (max-width:1199px) {
                            font-size: 40px;
                        }

                        span {
                            font-weight: 400;
                            color: #919191;
                            font-size: 16px;
                            text-transform: capitalize;
                            padding-left: 5px;
                            line-height: 40px;

                            @media (max-width:1199px) {
                                line-height: 35px;
                            }
                        }
                    }

                    p {
                        padding-bottom: 30px;
                        max-width: 282px;
                        margin: 0 auto;
                        border-bottom: 1px solid $theme-primary-color;
                    }
                }
            }

            .wpo-pricing-bottom {
                padding: 40px;
                padding-top: 35px;
                text-align: center;

                @media (max-width:1199px) {
                    padding: 40px 20px;
                }

                .wpo-pricing-bottom-text {
                    ul {
                        list-style: none;

                        li {
                            color: $text-light-color;
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 30px;
                            margin-bottom: 20px;

                            @media (max-width:1199px) {
                                margin-bottom: 15px;
                            }

                            @media (max-width:399px) {
                                font-size: 17px;
                                line-height: 20px;
                            }
                        }
                    }

                    a {
                        font-size: 16px;
                        color: $heading-color;
                        font-weight: 500;
                        display: inline-block;
                        margin-top: 20px;
                        position: relative;
                        padding: 10px 25px;
                        border: 1px solid $theme-primary-color;
                        position: relative;
                        background: $white;

                        &::before {
                            position: absolute;
                            content: '';
                            left: -5px;
                            top: -5px;
                            width: 100%;
                            height: 100%;
                            border: 1px solid $theme-primary-color;
                            z-index: -1;
                        }
                    }
                }
            }
        }

        .col {
            &:nth-child(2) {
                .wpo-pricing-item {
                    border: 1px dashed transparent;
                    background: $theme-primary-color-s2;

                    .wpo-pricing-top {

                        .pricing-thumb {

                            span {
                                color: $white;
                            }
                        }


                        .wpo-pricing-text {
                            border-bottom: 1px solid #fff;

                            h2 {
                                color: $white;

                                span {
                                    color: $white;
                                }
                            }
                        }
                    }

                    .wpo-pricing-bottom-text {
                        ul {
                            li {
                                color: $white;
                            }
                        }

                        a {
                            color: $theme-primary-color;
                            border: 1px solid transparent;

                            &::before {
                                border: 1px solid $white;
                                z-index: 1;
                            }
                        }
                    }

                }
            }

            &:last-child {
                .wpo-pricing-item {
                    margin-bottom: 0;
                }
            }
        }
    }
}

/* 3.7 Arkitek-testimonial-section */
.Arkitek-testimonial-section,
.Arkitek-testimonial-section-s2 {
    padding-top: 0;

    .testimonial-left-item {
        @media (max-width:991px) {}

        @media (max-width:767px) {
            width: 350px;
            height: 350px;
            margin: 0 auto;
        }

        @media (max-width:575px) {
            width: 300px;
            height: 300px;
        }

        @media (max-width:399px) {
            width: 250px;
            height: 250px;
        }

        .image {
            margin: 20px;
            outline: 2px dashed #799d997a;
            outline-offset: 15px;
            border-radius: 50%;

            img {
                border-radius: 50%;
                width: 100%;
            }
        }
    }


    .testimonial-item {
        margin-left: 40px;
        margin-right: 5px;

        @media (max-width:1399px) {
            margin-left: 30px;
            margin-right: 0;
        }

        @media (max-width:1199px) {
            margin-left: 0;
        }

        @media (max-width:767px) {
            margin-top: 30px;
            text-align: center;
        }

        .icon {
            display: inline-block;

            @media (max-width:1199px) {
                width: 100px;
                height: 100px;
            }

            @media (max-width:991px) {
                width: 80px;
                height: 80px;
            }
        }

        p {
            font-family: $heading-font;
            font-weight: 400;
            font-size: 30px;
            line-height: 50px;
            color: $text-light-color;
            margin-bottom: 80px;
            margin-top: 20px;
            position: relative;

            @media (max-width:1399px) {
                font-size: 29px;
                margin-top: 0;
                margin-bottom: 65px;
            }

            @media (max-width:1199px) {
                font-size: 25px;
                line-height: 40px;
                margin-bottom: 40px;
            }

            @media (max-width:991px) {
                font-size: 20px;
                line-height: 30px;
            }

            &:before {
                position: absolute;
                left: 0;
                top: 35px;
                width: 50%;
                height: 100%;
                content: "";
                border-bottom: 2px dashed #779a65ad;
                z-index: -1;

                @media (max-width:1199px) {
                    top: 20px;
                }

                @media (max-width:767px) {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            &::after {
                position: absolute;
                left: 0;
                top: 45px;
                width: 40%;
                height: 100%;
                content: "";
                border-bottom: 2px dashed #779a65ad;
                z-index: -1;

                @media (max-width:1199px) {
                    top: 30px;
                }

                @media (max-width:767px) {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        h3 {
            margin-top: 30px;
            font-family: $heading-font;
            font-weight: 400;
            font-size: 40px;
            color: $dark-gray;

            @media (max-width:1199px) {
                font-size: 35px;
            }

            @media (max-width:991px) {
                font-size: 25px;
                line-height: 30px;
            }
        }

        span {
            font-weight: 400;
            font-size: 22px;
            color: $text-light-color;

            @media (max-width:1199px) {
                font-size: 22px;
            }

            @media (max-width:991px) {
                font-size: 18px;
                line-height: 22px;
            }

            @media (max-width:767px) {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    &.s3 {
        padding-top: 120px;

        @media (max-width:991px) {
            padding-top: 90px;
        }

        @media (max-width:767px) {
            padding-top: 80px;
        }

        .testimonial-left-item .image {
            max-width: 590px;

            @media (max-width:1399px) {
                max-width: 500px;
            }
        }

        .slick-dots {
            text-align: left;
            bottom: -40px;
            margin-left: 30px;

            @media (max-width:1399px) {
                margin-left: 20px;
            }

            @media (max-width:1199px) {
                margin-left: 0px;
            }

            @media (max-width:767px) {
                text-align: center;
            }


            li {
                width: unset;
                height: unset;

                button {
                    border: none;
                    margin: 0 5px;
                    width: 15px;
                    height: 8px;
                    border-radius: 10px;
                    background: #ddd;

                    &::before {
                        display: none;
                    }

                }

                &.slick-active {
                    button {
                        background: $theme-primary-color;
                        width: 45px;
                    }
                }
            }

        }

    }
}

/*3.8 Arkitek-cta-section */
.Arkitek-cta-section,
.Arkitek-cta-section-s2 {
    background: url(../../images/cta-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    position: relative;

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: #779A65;
        opacity: 0.95;
        z-index: -1;
    }

    .cta-content {
        text-align: center;

        h5 {
            display: inline-block;
            color: $white;
            font-family: $base-font-s2;
            font-weight: 500;
            font-size: 18px;
            text-transform: uppercase;

            @media (max-width:767px) {
                font-size: 15px;
                margin-bottom: 8px;
            }
        }

        .call {
            display: block;
            color: $white;
            font-family: $heading-font;
            font-weight: 400;
            font-size: 60px;
            margin-bottom: 20px;

            @media (max-width:1199px) {
                font-size: 55px;
            }

            @media (max-width:991px) {
                font-size: 45px;
            }

            @media (max-width:767px) {
                font-size: 32px;
                margin-bottom: 7px;
            }
        }

        p {
            color: $white;
            font-weight: 500;
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 37px;

            @media (max-width:767px) {
                font-size: 15px;
                margin-bottom: 20px;
            }
        }

        .cta-btn {
            display: inline-block;
        }
    }
}

/* 3.9 wpo-blog-section */
.wpo-blog-section,
.wpo-blog-section-s2 {
    padding-bottom: 90px;

    .blog-wrap {
        margin-top: 35px;
    }

    .image {

        img {
            width: 100%;
        }
    }

    .blog-content {
        background: $white;
        border: 2px dashed #AFBDA8;
        margin: 0 30px;
        padding: 35px;
        position: relative;
        z-index: 1;
        margin-top: -95px;

        @media (max-width:1399px) {
            margin: 0 15px;
            margin-top: -50px;
        }

        @media (max-width:1199px) {
            margin: 0 10px;
            padding: 20px 15px;
            margin-top: -50px;
        }

        @media (max-width:991px) {
            margin: 0 10px;
            margin-top: -110px;
        }

        @media (max-width:399px) {
            padding: 10px;
            margin: 0 5px;
            margin-top: -110px;
        }

        h2 {

            a {
                font-family: $heading-font;
                font-weight: 400;
                font-size: 36px;
                line-height: 60px;
                color: $heading-color;
                transition: all .3s ease-in-out;

                @media (max-width:1399px) {
                    font-size: 30px;
                    line-height: 50px;
                }

                @media (max-width:575px) {
                    font-size: 25px;
                    line-height: 45px;
                }

                @media (max-width:399px) {
                    font-size: 18px;
                    line-height: 26px;
                }
            }

            &:hover {

                a {
                    color: $theme-primary-color;
                }
            }
        }

        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            color: $text-light-color;

            @media (max-width:399px) {
                font-size: 16px;
                line-height: 25px;
            }
        }

    }

    .thumb {

        ul {
            list-style: none;

            li {
                display: inline-block;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: #779A65;
                z-index: 1;

                @media (max-width:399px) {
                    font-size: 15px;
                    line-height: 22px;
                }

                a {
                    position: relative;
                    color: #779A65;
                    padding-right: 20px;
                    transition: all .3s ease-in-out;

                    &::before {
                        position: absolute;
                        content: '';
                        right: 5px;
                        top: 9px;
                        width: 5px;
                        height: 5px;
                        background: $theme-primary-color-s2;
                    }

                    &:hover {
                        color: $theme-primary-color;
                    }
                }
            }
        }
    }

    .grid {
        width: 50%;
        float: left;
        padding: 0 15px 30px;

        @media (max-width:991px) {
            width: 100%;
            float: none;
        }

        &:last-child {
            @media (max-width:991px) {
                padding-bottom: 0;
            }
        }
    }

    .blog-item.s2 {
        margin-bottom: 30px;

        .image {
            width: 330px;
            height: 300px;
            display: inline-block;

            @media (max-width:1199px) {
                width: 270px;
                height: 250px;
            }

            @media (max-width:991px) {
                width: 100%;
                height: 100%;
            }
        }

        .blog-content {
            width: 420px;
            margin: 0;
            margin-top: -265px;
            margin-left: 200px;

            @media (max-width:1399px) {
                margin-left: 140px;
            }

            @media (max-width:1199px) {
                margin-top: -220px;
                width: 380px;
                margin-left: 61px;
            }

            @media (max-width:991px) {
                margin: 0 10px;
                margin-top: -110px;
                width: 100%;
                padding: 20px 15px;
                width: 97%;
            }

            @media (max-width:767px) {
                width: 96%;
            }

            @media (max-width:575px) {
                width: 95%;
            }

            @media (max-width:399px) {
                padding: 10px;
                margin: 0 5px;
                margin-top: -110px;
                width: 96%;
            }

            h2 {
                margin-bottom: 0;

                a {
                    font-weight: 400;
                    font-size: 26px;
                    line-height: 60px;

                    @media (max-width:575px) {
                        font-size: 24px;
                        line-height: 45px;
                    }

                    @media (max-width:399px) {
                        font-size: 18px;
                        line-height: 26px;
                    }
                }

            }

            p {
                @media (max-width:399px) {
                    font-size: 16px;
                    line-height: 25px;
                    margin-bottom: 10px;
                }
            }

            .theme-btn-s2 {
                @media (min-width:992px) {
                    display: none;
                }
            }
        }
    }

}


.wpo-blog-section {
    .blog-content p {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .grid {
        &:first-child {
            .image img {
                min-height: 470px;
                object-fit: cover;

                @media (max-width:1399px) {
                    min-height: 445px;
                }

                @media (max-width:1199px) {
                    min-height: 375px;
                }

                @media (max-width:991px) {
                    min-height: 100%;
                }
            }
        }
    }

}