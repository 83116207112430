/*--------------------------------------------------------------
3. content
--------------------------------------------------------------*/

/*3.1 wpo-hero-slider*/

.wpo-hero-slider,
.static-hero,
.wpo-hero-slider-s2 {
	width: 100%;
	height: 850px;
	display: flex;
	position: relative;
	z-index: 0;

	@include media-query(991px) {
		height: 600px;
	}

	@include media-query(767px) {
		height: 500px;
	}

	.swiper-slide {
		overflow: hidden;
	}

	.swiper-container,
	.hero-container {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.slide-inner,
	.hero-inner {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: left;

		&:before {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: #040128;
			content: "";
			opacity: .6;
		}

		.slide-content {
			padding-bottom: 150px;
			z-index: 11;
			position: relative;

			@media(max-width:991px) {
				padding-bottom: 30px;
			}

			@media(max-width:767px) {
				padding-bottom: 0;
			}

		}
	}

	// slider controls
	.swiper-button-prev,
	.swiper-button-next {
		background: transparentize($white, 0.8);
		width: 60px;
		height: 60px;
		line-height: 60px;
		border: 2px solid $white;
		border-radius: 50%;
		opacity: 0.7;
		text-align: center;
		@include transition-time(0.3s);

		&:hover {
			opacity: 0.9;
		}

		@include media-query(767px) {
			display: none;
		}

		&:after{
			display: none;
		}
	}

	.swiper-button-prev {
		left: 25px;

		&:before {
			font-family: "themify";
			content: "\e629";
			font-size: 20px;
			color: #fff;
		}
	}

	.swiper-button-next {
		right: 25px;

		&:before {
			font-family: "themify";
			content: "\e628";
			font-size: 20px;
			color: #fff;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		@include media-query(991px) {
			display: none;
		}
	}

	.swiper-pagination-bullet {
		width: 12px;
		height: 12px;
		text-align: center;
		line-height: 12px;
		font-size: 12px;
		color: #000;
		opacity: 1;
		background: rgba(255, 255, 255, 0.2);

		@include widther(992px) {
			display: none;
		}
	}

	.swiper-pagination-bullet-active {
		color: #fff;
		background: $white;
		width: 12px;
		height: 12px;
		text-align: center;
		line-height: 12px;
	}

	.swiper-container-horizontal>.swiper-pagination-bullets,
	.swiper-pagination-custom,
	.swiper-pagination-fraction {
		bottom: 30px;
	}

	.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 0 8px;
	}
}


.wpo-hero-slider,
.static-hero,
.wpo-hero-slider-s2 {

	@include media-query(1199px) {
		height: 600px;
	}

	@include media-query(991px) {
		height: 600px;
	}

	@include media-query(767px) {
		height: 500px;
	}

	.swiper-slide {
		position: relative;
		z-index: 11;
	}


	.slide-title {
		max-width: 930px;
		margin: 0 auto;

		@include media-query(1199px) {
			max-width: 555px;
		}


		h2 {
			font-weight: 400;
			font-size: 100px;
			line-height: 100px;
			margin: 10px 0 15px;
			color: $white;
			text-align: center;

			@include media-query(1199px) {
				font-size: 50px;
			}

			@include media-query(991px) {
				font-size: 40px;
				line-height: 55px;
			}

			@include media-query(767px) {
				font-size: 30px;
				line-height: 36px;
			}
		}
	}

	.slide-text {
		max-width: 740px;
		text-align: center;
		margin: 0 auto;

		@include media-query(991px) {
			max-width: 500px;
		}

		p {
			color: $white;
			font-weight: 500;
			font-size: 20px;
			line-height: 30px;
			margin-bottom: 40px;
			color: #e2e2e2;
			text-align: center;

			@include media-query(991px) {
				font-size: 18px;
			}

			@include media-query(767px) {
				font-size: 16px;
				line-height: 22px;
				margin-bottom: 30px;
			}
		}
	}


	.slide-btns {
		text-align: center;
		margin: 0 auto;

		@include media-query(991px) {
			max-width: 400px;
		}

		.theme-btn {
			border-radius: 0;
			font-weight: 600;
			font-size: 18px;
			color: $white;
			background: $theme-primary-color;

			&::before {
				border: 1px dashed $theme-primary-color;
			}

		}
	}



}

/*-------------------------------------------
	hero style 1
--------------------------------------------*/
.static-hero,
.static-hero-s2,
.static-hero-s3 {
	height: 1000px;
	display: flex;
	position: relative;
	z-index: 1;
	overflow: hidden;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	@include media-query(1399px) {
		height: 900px;
	}

	@include media-query(1199px) {
		height: 820px;
	}

	@include media-query(991px) {
		height: 700px;
	}

	@include media-query(767px) {
		height: 500px;
	}


	&:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, .74);
		content: "";

		@media (min-width:992px) {
			display: none;
		}


	}



	.hero-container {

		.hero-inner {

			&::before {
				display: none;
			}

			.wpo-static-hero-inner {
				position: relative;
				margin-left: -120px;

				@media (max-width:1649px) {
					margin-left: -60px;
				}

				@media (max-width:1499px) {
					margin-left: -10px;
				}

				@media (max-width:1199px) {
					margin: 0;
					margin-top: 50px;
				}

				@media (max-width:991px) {
					margin-top: 100px;
				}

				@media (max-width:767px) {
					margin-top: 0;
				}

				span {
					font-family: $base-font-s2;
					font-weight: 400;
					font-size: 16px;
					line-height: 30px;
					text-transform: uppercase;
					color: $theme-primary-color-s2;

				}

				.title {
					margin-top: 18px;
					font-family: $heading-font;
					font-weight: 400;
					font-size: 70px;
					line-height: 90px;
					color: $dark-gray;
					margin-bottom: 25px;

					@media (max-width:1199px) {
						margin-top: 0px;
						font-size: 60px;
						line-height: 75px;
						margin-bottom: 16px;
					}

					@media (max-width:767px) {
						font-size: 30px;
						line-height: 40px;
					}
				}


				.sub-title {
					font-weight: 400;
					font-size: 18px;
					line-height: 30px;
					color: $text-light-color;
					margin-bottom: 60px;
					margin-right: 155px;

					@media (max-width:1199px) {
						margin-right: 0;
					}

					@media (max-width:767px) {
						margin-bottom: 30px;
					}
				}

				.theme-btn {
					color: $white;
					background: $theme-primary-color;

					&::before {
						border: 1px dashed $theme-primary-color;
					}

					&:hover {
						color: $dark-gray;
					}
				}
			}

		}
	}



	.shape-1 {
		position: absolute;
		top: 10%;
		left: 0;
		z-index: -11;

		@media (max-width:991px) {
			display: none;
		}

	}

	.shape-2 {
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: -11;

		@media (max-width:991px) {
			display: none;
		}
	}

	.slider-img-1 {
		position: absolute;
		width: 1060px;
		height: 630px;
		top: 31%;
		right: -28%;
		transform: translate(-50%, -50%);
		z-index: -11;
		clip-path: polygon(0 0, 100% 0, 100% 100%, 16% 70%);

		@media (max-width:1849px) {
			top: 31%;
			right: -30%;
		}

		@media (max-width:1799px) {
			top: 31%;
			right: -36%;
		}

		@media (max-width:1749px) {
			width: 985px;
			height: 620px;
			clip-path: polygon(0 0, 100% 0, 100% 97%, 16% 70%);
		}

		@media (max-width:1549px) {
			clip-path: polygon(0 0, 100% 0, 100% 79%, 16% 70%);
			width: 913px;
			height: 695px;
			top: 34.7%;
			right: -37%;
		}

		@media (max-width:1399px) {
			width: 800px;
			height: 600px;
			top: 31%;
			right: -37%;
		}

		@media (max-width:1299px) {
			right: -38%;
		}

		@media (max-width:1199px) {
			right: -20%;
			top: 0;
			transform: none;
		}

		@media (max-width:991px) {
			right: -26%;
			top: -12%;
		}

		@media (max-width:767px) {
			left: 0;
			top: 0;
			clip-path: none;
			width: 100%;
			height: 100%;
		}

		img {
			width: 100%;
			object-fit: cover;

			@media (max-width:767px) {
				height: 100vh;
			}
		}
	}


	.slider-img-2 {
		position: absolute;
		height: 671px;
		width: 934.5px;
		top: 67%;
		right: 0%;
		transform: translate(-50%, -50%);
		z-index: -11;
		clip-path: polygon(55% 17%, 100% 31%, 87% 100%, 5% 100%);

		@media (max-width:1849px) {
			top: 67%;
			right: 1%;
		}

		@media (max-width:1799px) {
			top: 67%;
			right: -6%;
			clip-path: polygon(54% 17%, 99% 31%, 86% 100%, 7% 100%);
		}

		@media (max-width:1749px) {
			top: 67%;
			right: -13%;
			clip-path: polygon(50% 17%, 100% 31%, 87% 100%, 7% 100%);
		}

		@media (max-width:1699px) {
			right: -12.5%;
		}

		@media (max-width:1649px) {
			right: -12%;
		}

		@media (max-width:1599px) {
			right: -11%;
		}

		@media (max-width:1549px) {
			width: 849px;
			top: 73%;
			right: -7%;
			clip-path: polygon(59% 16%, 100% 21%, 87% 100%, 17% 100%);
		}

		@media (max-width:1499px) {
			top: 73%;
			right: -6.8%;
		}

		@media (max-width:1449px) {
			right: -5.5%;
		}

		@media (max-width:1399px) {
			right: -8%;
			top: 70%;
			clip-path: polygon(69% 18%, 100% 21%, 87% 100%, 34% 100%);
		}

		@media (max-width:1349px) {
			right: -7%;
		}

		@media (max-width:1199px) {
			display: none;
		}


		img {
			width: 100%;
			object-fit: cover;
		}

	}

	.slider-img-3 {
		position: absolute;
		height: 510px;
		width: 633.5px;
		top: 75%;
		right: -18%;
		transform: translate(-50%, -50%);
		z-index: -11;
		clip-path: polygon(25% 10%, 100% 30%, 100% 100%, 6% 100%);

		@media (max-width:1799px) {
			top: 75%;
			right: -25%;
		}

		@media (max-width:1749px) {
			top: 75%;
			right: -33%;
		}

		@media (max-width:1549px) {
			clip-path: polygon(24% 8%, 100% 16%, 100% 100%, 9% 100%);
		}

		@media (max-width:1399px) {
			top: 72%;
			right: -37%;
		}

		@media (max-width:1299px) {
			right: -38%;
		}

		@media (max-width:1249px) {
			right: -39%;
		}

		@media (max-width:1199px) {
			right: -31%;
			top: 79%;
		}

		@media (max-width:1099px) {
			right: -32%;
		}

		@media (max-width:991px) {
			right: -40%;
			top: 80%;
		}

		@media (max-width:899px) {
			right: -41%;
		}

		@media (max-width:799px) {
			right: -42%;
		}

		@media (max-width:767px) {
			display: none;
		}

		img {
			width: 100%;
			object-fit: cover;
		}
	}

}

/*-------------------------------------------
	wpo-hero-slider-1
--------------------------------------------*/
.wpo-hero-slider-1 {
	height: 900px;
	position: relative;
	border-top: 1px solid #D9D9D9;

	@include media-query(1199px) {
		height: 700px;
	}

	@include media-query(991px) {
		height: 450px;
	}

	@include media-query(767px) {
		height: 400px;
	}


	&::before {
		position: absolute;
		content: '';
		left: 210px;
		top: 0;
		width: 1px;
		height: 100%;
		background: #D9D9D9;

		@include media-query(1799px) {
			left: 150px;
		}

		@include media-query(1499px) {
			left: 80px;
		}

		@include media-query(991px) {
			left: 20px;
		}

		@include media-query(575px) {
			left: 5px;
		}
	}

	&::after {
		position: absolute;
		content: '';
		right: 170px;
		top: 0;
		width: 1px;
		height: 100%;
		background: #D9D9D9;

		@include media-query(1799px) {
			right: 120px;
		}

		@include media-query(1499px) {
			right: 80px;
		}

		@include media-query(991px) {
			right: 20px;
		}

		@include media-query(575px) {
			right: 5px;
		}
	}

	.slide {
		height: 900px;
		position: relative;

		@include media-query(1199px) {
			height: 700px;
		}

		@include media-query(767px) {
			height: 400px;
		}

		&::before {
			position: absolute;
			content: '';
			left: 51%;
			top: 42%;
			transform: translate(-50%, -50%);
			width: 77%;
			height: 669px;
			background: #F1F5EE;
			z-index: -11;

			@include media-query(1799px) {
				width: 80%;
				height: 600px;
			}

			@include media-query(1699px) {
				top: 40%;
			}

			@include media-query(1599px) {
				height: 569px;
				top: 35%;
			}

			@include media-query(1499px) {
				left: 50%;
				width: 85%;
			}

			@include media-query(1399px) {
				height: 530px;
				top: 30%;
			}

			@include media-query(1199px) {
				height: 400px;
				top: 25%;
				width: 82%;
			}

			@include media-query(991px) {
				top: 30%;
				width: 93%;
			}

			@include media-query(767px) {
				top: 50%;
				width: 93%;
				height: 100%;
			}

			@include media-query(575px) {
				width: 100%;
				left: 50%;
				top: 50%;
			}
		}
	}

	.slide-caption {
		margin-top: 95px;
		z-index: 1;

		@include media-query(1699px) {
			margin-left: 50px;
		}

		@include media-query(1599px) {
			margin-left: 80px;
		}

		@include media-query(1199px) {
			margin-top: 40px;
		}

		@include media-query(991px) {
			margin-left: 0;
			margin-top: 85px;
		}

		@include media-query(991px) {
			margin-top: 100px;
		}

		@include media-query(767px) {
			margin-top: 80px;
		}

	}

	.slide-title {

		h2 {
			font-weight: 400;
			font-size: 85px;
			line-height: 120px;
			color: $heading-color;

			@include media-query(1399px) {
				font-size: 80px;
				line-height: 100px;
			}

			@include media-query(1199px) {
				font-size: 60px;
				line-height: 70px;
			}

			@include media-query(991px) {
				font-size: 40px;
				line-height: 50px;
			}

			@include media-query(575px) {
				font-size: 30px;
				line-height: 40px;
			}
		}
	}

	.slide-subtitle {
		p {
			font-family: $heading-font;
			font-weight: 400;
			font-size: 20px;
			line-height: 30px;
			color: $text-light-color;
			margin-bottom: 30px;
			margin-right: 130px;

			@include media-query(1199px) {
				margin-bottom: 15px;
			}

			@include media-query(991px) {
				margin-right: 0;
			}

			@include media-query(767px) {
				margin-right: 30px;
			}
		}
	}

	.theme-btn-s2 {
		color: $theme-primary-color-s2;
	}

	.slider-pic {
		position: absolute;
		right: 220px;
		top: 150px;
		width: 910px;
		height: 680px;
		background: $white;
		padding: 15px;

		@media (max-width:1799px) {
			width: 750px;
			height: 640px;
		}

		@include media-query(1699px) {
			right: 180px;
		}

		@include media-query(1599px) {
			width: 650px;
			height: 488px;
		}

		@include media-query(1499px) {
			right: 135px;
		}

		@include media-query(1399px) {
			right: 100px;
			width: 580px;
			height: 450px;
		}

		@include media-query(1199px) {
			width: 430px;
			height: auto;
			top: 50px;
		}

		@include media-query(991px) {
			right: 40px;
			width: 400px;
		}

		@include media-query(767px) {
			display: none;
		}

		.slider-item {
			img {
				width: 100%;
			}
		}

	}

	.hero-left-img {
		background: $white;
		width: 380px;
		padding: 15px;
		display: inline-block;
		position: absolute;
		left: 26%;
		top: 29%;
		transform: translate(-50%, 50%);

		@include media-query(1399px) {
			width: 350px;
		}

		@include media-query(1199px) {
			width: 300px;
			top: 24%;
		}

		@include media-query(991px) {
			display: none;
		}

		img {
			width: 100%;
		}
	}


	.hero-social-icon {
		list-style: none;
		position: absolute;
		left: -7%;
		top: 44%;
		transform: rotate(-90deg);

		@include media-query(1799px) {
			left: -9%;
		}

		@include media-query(1499px) {
			left: -14%;
		}

		@include media-query(1399px) {
			left: -17%;
		}

		@include media-query(1199px) {
			left: -19%;
		}

		@include media-query(991px) {
			display: none;
		}

		li {
			display: inline-block;

			a {
				font-family: $heading-font;
				font-weight: 400;
				font-size: 25px;
				line-height: 40px;
				text-transform: uppercase;
				color: $text-light-color;
				margin-right: 50px;
				transition: all .3s ease-in-out;

				&:hover {
					color: $theme-primary-color
				}
			}
		}
	}



	/** slider controls **/


	.slick-dots {
		width: 0;
		top: 52%;
		left: 112%;
		transform: translate(-50%, -50%);

		@include media-query(1499px) {
			left: 110%;
		}

		@include media-query(1399px) {
			left: 105%;
		}

		@include media-query(1199px) {
			top: 77%;
		}

		@include media-query(991px) {
			display: none;
		}


		li {
			margin: 0;

		}

		li.slick-active button {
			color: $theme-primary-color;
			transition: all .3s ease-in-out;
			font-size: 50px;
			line-height: 40px;
			color: #525252;

			@include media-query(991px) {
				font-size: 25px;
				line-height: 30px;
			}
		}

		li.slick-active {
			color: $theme-primary-color;
			position: relative;
		}

		button:before,
		button:before {
			display: none;
		}
	}

	.slick-dots li {
		display: block;
		height: 60px;
		width: 60px;
		cursor: pointer;
		color: $heading-color;

		@include media-query(991px) {
			height: 40px;
			width: 40px;
		}
	}

	.slick-dots li button {
		height: 60px;
		width: 60px;
		display: block;
		cursor: pointer;
		font-family: $heading-font;
		font-weight: 400;
		font-size: 30px;
		line-height: 40px;
		text-transform: uppercase;
		color: #C9C9C9;

		@include media-query(991px) {
			font-size: 20px;
			line-height: 30px;
			height: 40px;
			width: 40px;
		}
	}



}

/*-------------------------------------------
	hero style 2
--------------------------------------------*/
.static-hero-s2,
.static-hero-s3 {
	display: block;

	@include media-query(575px) {
		height: 600px;
	}

	@include media-query(399px) {
		height: 500px;
	}

	&::before {
		position: unset;
		display: none;
	}

	.hero-inner {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		text-align: left;
	}

	.wpo-static-hero-inner {
		top: -85px;
	}

	.hero-container {

		.hero-inner {
			.wpo-static-hero-inner {
				span {
					@media (max-width:399px) {
						font-size: 15px;
					}
				}

				.title {
					margin-top: 0;

					@media (max-width:1399px) {
						font-size: 65px;
						line-height: 80px;
					}

					@media (max-width:1199px) {
						font-size: 52px;
						line-height: 74px;
					}

					@media (max-width:767px) {
						font-size: 48px;
						line-height: 63px;
					}

					@media (max-width:575px) {
						font-size: 32px;
						line-height: 40px;
					}

				}
			}
		}
	}

	.slider-img-4 {
		right: 0;
		margin: 0 auto;
		top: 20px;
		width: 80%;
		height: 980px;
		align-items: center;
		background-position: 50%;
		background-size: cover;
		display: flex;
		justify-content: center;
		position: absolute;
		text-align: right;
		z-index: -1;
		background-image: url(../../images/slider/slide-7.jpg);

		@media (max-width:1399px) {
			height: 875px;
		}

		@media (max-width:1199px) {
			height: 800px;
		}

		@media (max-width:991px) {
			height: 650px;
			top: 40px;
		}

		&:after {
			background: url(../../images/slider/shape-3.jpg) no-repeat;
			background-size: cover;
			content: "";
			height: 100%;
			right: 0;
			mix-blend-mode: screen;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 999;
		}

	}
}

/*-------------------------------------------
    static-hero-s3
--------------------------------------------*/
.static-hero-s3 {
	background: url(../../images/slider/slide-11.jpg);
	@include media-query(1399px) {
		height: 900px;
	}

	@include media-query(1199px) {
		height: 700px;
	}

	@include media-query(991px) {
		height: 600px;
	}
	@include media-query(767px) {
		height: 500px;
	}
	.hero-inner {
		align-items: center;
		
		.wpo-static-hero-inner {
			top: 0;
			.title {
				font-family: $heading-font;
				font-weight: 300;
				font-size: 70px;
				line-height: 100px;
				color: $heading-color;
			}

			p {
				font-family:$base-font;
				font-weight: 400;
				font-size: 18px;
				line-height: 30px;
				color: $text-light-color;
				max-width: 575px;
				margin-bottom: 60px;
				@include media-query(767px) {
					margin-bottom: 30px;
				}
			}
			
		}
		
	}
	.hero-container .hero-inner .wpo-static-hero-inner{
		@include media-query(767px) {
			margin-top: 100px;
		}
	}
}

.static-hero-s3 .hero-container .hero-inner .wpo-static-hero-inner .theme-btn::before{
	border: 1px solid $theme-primary-color;
}
