.theme-btn {
	background: $white;
	display: inline-block;
	font-weight: 600;
	padding: 20px 45px;
	padding-bottom: 16px;
	border: 0;
	text-transform: uppercase;
	position: relative;
	font-family: $base-font-s2;
	font-size: 16px;
	text-align: center;
	color: #D9916A;
	transition: all .3s ease-in-out;
	z-index: 1;

	&::before {
		position: absolute;
		content: "";
		left: -5px;
		top: -5px;
		width: 100%;
		height: 100%;
		border: 1px solid $white;
		transition: all .3s ease-in-out;
		z-index: -1;
	}

	&:hover,
	&:focus,
	&:active {
		color: $dark-gray;
	}



	@include media-query(991px) {
		font-size: 15px;
	}

	@include media-query(767px) {
		padding: 12px 18px;
		font-size: 14px;
	padding-bottom: 8px;
	}
}

.theme-btn-s2 {
	color: $theme-primary-color;
	display: inline-block;
	font-family: $base-font-s2;
	font-weight: 600;
	font-size: 16px;
	line-height: 25px;
	text-decoration-line: underline;
	text-transform: uppercase;
	transition: all .3s ease-in-out;

	@include media-query(991px) {
		font-size: 14px;
	}

	&:hover,
	&:focus,
	&:active {
		color: $theme-primary-color-s2;
	}



	@include media-query(767px) {
		font-size: 13px;
	}
}



.view-cart-btn {
	@extend .theme-btn;
	display: block;
	margin-top: 15px;
	border-radius: 5px;
	padding: 10px 45px;
	background: $theme-primary-color-s2;
	color: $white;
	&::before {
		border: 1px dashed $theme-primary-color-s2;
	}
	&:after {
		display: none;
	}

	&:hover {
		background-color: darken($theme-primary-color, 8%);
	}

	&.s1 {
		background: #ddd;
		
		&::before {
			border: 1px dashed #ddd;
		}

		&:hover {
			background-color: darken(#ddd, 8%);
		}
	}

}