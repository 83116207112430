/*--------------------------------------------------------------
17. error-404-section
--------------------------------------------------------------*/
.error-404-section {
    text-align: center;

    .error-message {
        margin-top: 70px;
        padding: 0 200px;

        @include media-query(991px) {
            margin-top: 50px;
            padding: 0 100px;
        }

        @include media-query(767px) {
            padding: 0;
        }

        h3 {
            font-size: 30px;
            margin: 0 0 0.8em;
        }

        p {
            margin-bottom: 1.8em;
            color: $text-light-color;
        }

        .theme-btn {
            padding: 17px 40px;
            border: none;
            background: $theme-primary-color;
            color: #fff;
            transition: all 0.3s;
            outline: none;

            &::before {

                border: 1px dashed $theme-primary-color;
            }

        }
    }

}