/*=====================================================
11. wpo-project-single
======================================================*/
.wpo-project-single-area {
    padding-bottom: 70px;

    @media (max-width:991px) {
        padding-bottom: 40px;
    }

    @media (max-width:767px) {
        padding-bottom: 30px;
    }

    .wpo-project-single-wrap {
        .wpo-project-single-title {
            h3 {
                font-weight: 400;
                font-size: 35px;
                line-height: 130.5%;
                margin-bottom: 20px;

                @media(max-width:767px) {
                    font-size: 25px;
                }
            }
        }

        .wpo-project-single-item {
            margin-bottom: 50px;

            p {
                color: $text-light-color;
            }

            .wpo-project-single-main-img {
                margin-bottom: 50px;
                margin-top: 20px;

                .owl-controls {
                    width: 100%;
                    margin: 0;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    @include translatingY();
                }

                .owl-nav [class*=owl-]:hover {
                    background: $theme-primary-color;
                    color: $white;
                }

                .owl-nav [class*=owl-] {
                    background: $white;
                    width: 50px;
                    height: 50px;
                    line-height: 58px;
                    padding: 0;
                    margin: 0;
                    border-radius: 50%;
                    color: $theme-primary-color;
                    @include transition-time(0.3s);
                    border: 0;

                    .fi {
                        &::before {
                            font-size: 20px;
                        }
                    }
                }

                .owl-nav .owl-prev,
                .owl-nav .owl-next {
                    position: absolute;
                    top: 50%;
                    @include translatingY();
                }

                .owl-nav .owl-prev {
                    left: 15px;
                }

                .owl-nav .owl-next {
                    right: 15px;
                }
            }

            .wpo-project-single-content-des-right {
                max-width: 380px;
                flex-basis: 35%;
                margin-left: auto;
                margin-bottom: 50px;

                @media(max-width:1200px) {
                    flex-basis: 100%;
                    margin-bottom: 40px;
                }

                @media(max-width:991px) {
                    margin-left: 0;
                    max-width: 100%;
                    margin-top: 20px;
                }

                ul {
                    list-style: none;
                    padding: 50px 30px;
                    box-shadow: 0px 2px 10px rgba(14, 29, 44, 0.15);

                    @media(max-width:575px) {
                        padding: 50px 20px;
                    }

                    li {
                        display: flex;
                        padding: 10px 0;
                        font-size: 16px;
                        color: $dark-gray;
                        font-weight: 600;
                        position: relative;
                        justify-content: space-between;
                        font-weight: 600;

                        span {
                            flex-basis: 60%;
                            color: $text-light-color;
                            font-weight: 400;
                        }

                        &:last-child {
                            padding-bottom: 0;
                            border-bottom: 0;
                        }

                        &:first-child {
                            padding-top: 0;
                        }
                    }
                }
            }

            &.related-project {
                @media (max-width:991px) {
                    margin-bottom: 20px;
                }
            }
        }

        .list-widget {
            ul {
                list-style: none;

                li {
                    padding: 7px 0;
                    padding-left: 35px;
                    position: relative;
                    color: $text-light-color;

                    &:before {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 25px;
                        height: 25px;
                        line-height: 25px;
                        border-radius: 50%;
                        background: transparentize($theme-primary-color, .9);
                        content: "\e64c";
                        font-family: "themify";
                        text-align: center;
                        color: $theme-primary-color;
                        font-size: 14px;
                    }
                }
            }
        }

        .wpo-p-details-img {
            margin-bottom: 10px;
        }

        .wpo-project-single-item-quote {
            padding: 45px;
            background: transparentize($theme-primary-color, .9);
            text-align: center;
            border-radius: 15px;

            @media(max-width:991px) {
                padding: 20px;
                margin-top: 30px;
            }

            p {
                font-size: 22px;
                margin-bottom: 30px;
                color: $dark-gray;
                line-height: 44px;

                @media(max-width:991px) {
                    font-size: 16px;
                    margin-bottom: 20px;
                }
            }

            span {
                font-size: 22px;
                font-weight: 600;
                color: $dark-gray;

                @media(max-width:991px) {
                    font-size: 16px;
                }

                span {
                    color: $theme-primary-color;
                    font-weight: 400;
                }
            }
        }

        .list-widget-s {
            @media(max-width:991px) {
                margin-top: 30px;
            }
        }

        .wpo-project-single-gallery {
            margin-bottom: 30px;
        }

        .wpo-project-contact-area {
            padding: 30px;
            border-radius: 20px;
            box-shadow: 0px 2px 8px 2px rgba(22, 14, 71, .10);

            .wpo-contact-title {
                margin-bottom: 30px;

                h2 {
                    font-weight: 400;
                }
            }

            .wpo-contact-form-area {
                .col {
                    margin-bottom: 30px;
                }

                .form-control {
                    height: 50px;
                    border: 0;
                    border-bottom: 1px solid $border-color;
                    border-radius: 0;
                    background: none;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }

                textarea.form-control {
                    height: 120px;
                }

                select.form-control {
                    background: transparent url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
                }
                .errorMessage{
                    margin-top: 5px;
                    color: red;
                }

                .submit-area {
                    .theme-btn {
                        padding: 17px 40px;
                        border: none;
                        background: $theme-primary-color;
                        color: #fff;
                        transition: all 0.3s;
                        outline: none;

                        &::before {
                            display: none;
                            position: unset;
                        }
                    }

                }
            }
        }

        .project-single {
            background: #f5f5f5;
            transition: all .4s ease-in-out;
            padding: 35px;

            @media (max-width:991px) {
                margin-bottom: 30px;
            }

            @media (max-width:1280px) {
                padding: 30px 25px;
            }

            @media (max-width:580px) {
                padding: 25px 15px;
            }

            .project-single-img {
                overflow: hidden;

                img {
                    width: 100%;
                    transition: all .4s ease-in-out;
                    max-height: 200px;
                    object-fit: cover;
                }
            }

            .project-single-text {
                padding-top: 35px;
                text-align: left;


                a {
                    font-family: $heading-font;
                    text-transform: capitalize;
                    font-size: 25px;
                    font-weight: 400;
                    line-height: 30px;
                    margin-bottom: 0;
                    margin-top: 0px;
                    transition: all .4s ease-in-out;
                    display: inline-block;
                    color: $dark-gray;

                    @media (max-width:1499px) {
                        font-size: 20px;
                    }
                }
            }

            &:hover {

                .project-single-img {
                    img {
                        transform: scale(1.1);
                    }
                }

                .project-single-text {
                    a {
                        h2 {
                            color: $theme-primary-color;
                        }
                    }

                }

            }
        }


    }
}